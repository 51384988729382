<template>
  <div id="lang">
    <div class="divbig">
      <div class="div" @click="getLang('zh')">
        <cube-button light>中文</cube-button>
      </div>
      <div class="div" @click="getLang('mn')">
        <cube-button light class="but"><span class="aqt_lang_mn titlefont">ᠮᠣᠩᠭᠣᠯ</span></cube-button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";

export default {
  data() {
    return {};
  },
  methods: {
    getLang(lang) {
      if (lang == 'zh') {
        this.$router.push({
          path:'/index_cn',
          query:{
            number:this.$route.query.number
          }
        })
      } else if (lang == 'mn') {
        this.$router.push({
          path:'/index_mn',
          query:{
            number:this.$route.query.number
          }
        })
      }
      this.getsidebar_pic()
    },

    getsidebar_pic() {
      localStorage.removeItem('theme_color')
      const _this = this;
      const id = this.$route.query.number ? this.$route.query.number : (JSON.parse(localStorage.getItem("theme_color")).library_id ? JSON.parse(localStorage.getItem("theme_color")).library_id : '')
      _this.$http.get('api/web/v1/h5/search?library_id=' + id)
          .then((val) => {
            let a = {
              theme_color: val.data.theme_color,
              details_pic: val.data.details_pic,
              sidebar_pic: val.data.sidebar_pic,
              name: val.data.name_lang,
              library_id: number_id
            }
            localStorage.setItem('theme_color', JSON.stringify(a))
            store.commit('increment', a)
            function plusReady() {
              // 在这里调用plus api
              this.changeSys(JSON.parse(localStorage.getItem('theme_color')).theme_color)
            }

            if (window.plus) {
              plusReady();
            } else {
              document.addEventListener('plusready', plusReady(), false);
            }

            Vue.prototype.library_id = number_id//馆藏id  48内蒙古财经大学图书馆 all全部
            this.changeThemeColor(JSON.parse(localStorage.getItem('theme_color')).theme_color)
          }).catch((err) => {
      })
    },

    // 设置公共的颜色样式
    changeThemeColor(color) {
      document.body.style.setProperty('--themeColor', color);
      console.log('color', color)
    },

    //设置手机端系统栏颜色，
    changeSys(color) {
      plus.navigator.setStatusBarBackground(color);
    },
    //馆藏id加密
    // getlibraryId(){
    //   const _this = this;
    //   _this.$http.get('api/web/v1/h5/getLibraryIdEncryption?library_id=583')
    //       .then((val) => {
    //         console.log(val, '加密的馆藏id')
    //       }).catch((err) => {
    //   })
    // }

  },
  computed: {
    // 是否显示资源
    // lang () {
    //   return this.$store.getters.lang
    // },

  },
  created() {
    // this.getlibraryId()
  },
};
</script>

<style lang="less">
#lang {
  min-height: 100vh;
  width: 100%;
  // background-color: var(--themeColor);
  background-color: #fff;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .divbig {
    width: 50vw;
    height: 18vh;
    margin: auto;

    .div {
      width: 100%;
      margin-bottom: 2rem;

      .cube-btn-light {
        background-color: var(--themeColor);
        color: #fff;
      }

      .but {
        padding: 8px 16px;
      }
    }
  }
}
</style>
